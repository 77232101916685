<template>
	<v-text-field
		v-model="stringValue"
		@change="onChange"
		:label="label"
		:rules="rules"
		:prepend-icon="prependIcon"
		:counter="maxlen"
		:required="required"
		:clearable="clearable"
		:hint="hint"
		:tabindex="tabindex"
		persistent-hint
		validate-on-blur
		autocomplete="off"
	>
		<template #label v-if="required">
			{{ label }}
			<span style="color: red"> *</span>
		</template>
	</v-text-field>
</template>
<script>
export default {
	model: {
		prop: `value`
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		prependIcon: {
			type: String,
			default: 'mdi-city'
		},
		label: {
			type: String,
			default: 'Business Name'
		},
		hint: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		minlen: {
			type: Number,
			default: 2
		},
		maxlen: {
			type: Number,
			default: 196
		},
		clearable: {
			type: Boolean,
			default: true
		},
		tabindex: {
			type: Number,
			default: 0
		},
		disableRequiredValidation: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		stringValue: null
	}),
	created() {
		this.businessName = this.value;
	},
	computed: {
		rules() {
			return [
				(v) =>
					this.disableRequiredValidation || !!v || this.$t('errors.required', { key: this.label }),
				(v) =>
					!v ||
					v.length >= this.minlen ||
					this.$t('errors.minlen', { key: this.label, minlen: this.minlen }),
				(v) =>
					!v ||
					v.length <= this.maxlen ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen })
			];
		}
	},
	methods: {
		onChange() {
			if (this.stringValue) this.stringValue = this.stringValue.trim();
			this.$emit(`input`, this.stringValue);
		}
	}
};
</script>
