import Vue from 'vue';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

import VueCookies from 'vue-cookies';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import customAxios from '@/plugins/custom-axios';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.prototype.$axios = customAxios();

Vue.use(VueCookies);
Vue.use(VueTelInputVuetify, {
	vuetify
});

new Vue({
	router,
	vuetify,
	i18n,
	render: (h) => h(App)
}).$mount('#app');
