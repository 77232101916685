<template>
	<div>
		<v-checkbox v-model="agreed" @change="chagend" :rules="rules" dense>
			<template #label>
				<div class="caption grey--text">
					{{ $t('messages.privacy_policy_and_service_agreement_1') }}
					<!-- eslint-disable-next-line vue/no-template-target-blank -->
					<a target="_blank" href="https://www.shiftinc.jp/privacy/" @click.stop>{{
						$t('labels.privacy_policy')
					}}</a>
					{{ $t('messages.privacy_policy_and_service_agreement_2') }}
					<!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
					<a class="text-decoration-underline" @click.stop.prevent="openDialog"
						>{{ $t('labels.service_agreement') }}
					</a>
					{{ $t('messages.privacy_policy_and_service_agreement_3') }}
				</div>
			</template>
		</v-checkbox>
		<v-dialog v-model="dialog" fullscreen>
			<v-card>
				<v-card-text>
					<v-container class="overflow-y-auto">
						<v-row>
							<div class="text-h5 text--primary my-4">
								{{ $t('labels.service_agreement') }} {{ tosTitle }}
							</div>
							<div v-html="tosText" />
							<div>{{ tosRevisionOn | dateFormat }}</div>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn fixed bottom right color="primary" @click="dialog = false">
						{{ $t('labels.dialog.close') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { marked } from 'marked';
import { DateTime, Settings } from 'luxon';

export default {
	props: {
		value: {
			type: Number,
			default: null
		}
	},
	data: () => ({
		agreed: false,
		dialog: false,
		tosTitle: '',
		tosRevisionOn: '',
		tosBody: ''
	}),
	watch: {
		'$i18n.locale': {
			immediate: true,
			async handler() {
				await this.init();
			}
		}
	},
	filters: {
		dateFormat(value) {
			if (!value) return '';
			return DateTime.fromFormat(value, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_FULL);
		}
	},
	computed: {
		rules() {
			return [this.agreed || this.$t('errors.privacy_policy_and_service_agreement')];
		},
		tosText() {
			return marked(this.tosBody);
		}
	},
	methods: {
		async init() {
			this.agreed = false;
			Settings.defaultLocale = this.$i18n.locale;

			try {
				const {
					data: { title, revisionOn, body, id: tosId }
				} = await this.$axios.get(`/api/v2/private/tos/jp/${this.$i18n.locale}`);
				this.tosTitle = title;
				this.tosRevisionOn = revisionOn;
				this.tosBody = body;
				this.$emit(`service-agreement-id`, tosId);
			} catch (e) {
				this.$emit(`on-error`, e);
				console.error(e.message);
			}
		},
		chagend() {
			this.$emit(`input`, this.agreed ? DateTime.now().toUnixInteger() : null);
		},
		openDialog() {
			this.dialog = true;
		}
	}
};
</script>
