import axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';
import axiosRetry, { exponentialDelay } from 'axios-retry';

export default () => {
	const instance = axios.create();
	instance.interceptors.request.use(
		(request) => {
			const { data, params } = request;
			if (params)
				return {
					...request,
					params: snakecaseKeys(params)
				};
			if (!data) return request;

			return {
				...request,
				data: typeof data === 'string' ? data : snakecaseKeys(data, { deep: true })
			};
		},
		(e) => Promise.reject(e)
	);
	instance.interceptors.response.use(
		(response) => {
			const { data } = response;
			if (!data) return response;

			return {
				...response,
				data: camelcaseKeys(response.data, { deep: true, exclude: [`i18n_label`] })
			};
		},
		(e) => Promise.reject(e)
	);
	// 今後4XX 系も追加する場合、サーバーへの負荷関連429を除く
	axiosRetry(instance, {
		retryCondition: (error) =>
			error.code !== 'ECONNABORTED' &&
			(!error.response || (error.response.status >= 500 && error.response.status !== 503)),
		retryDelay: exponentialDelay
	});
	return instance;
};
