<template>
	<v-card width="560" class="mx-2 mt-10 pa-0" elevation="1" id="multi-factor-auth">
		<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
		<div v-if="!expired">
			<v-card-title> {{ $t('titles.mfa') }}</v-card-title>
			<v-alert
				class="text-subtitle-2 ma-3"
				border="left"
				type="error"
				dense
				dismissible
				:value="!!error"
				@input="error = null"
			>
				<span v-if="error">{{ $t(`errors.${error}`) }} </span>
			</v-alert>
			<v-alert
				:value="!!isCountingDown"
				class="text-subtitle-2 ma-3"
				border="left"
				type="success"
				dense
			>
				{{ $t(`messages.send_verification_code`) }}
			</v-alert>
			<v-card-subtitle>{{ $t('messages.mfa') }}</v-card-subtitle>
			<v-card-text align="center">
				<PincodeInput v-model="code" :length="6" :secure="false" placeholder="0" />
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn
					v-if="!body.mfaAppEnabled"
					class="mft-button"
					depressed
					color="success"
					@click="sendMail"
					min-width="100px"
					:tabindex="1"
					:disabled="mailLoading || timerCount > 0"
					:loading="mailLoading"
				>
					<v-icon v-if="!isCountingDown">mdi-cached</v-icon>
					<div v-if="isCountingDown" class="countdown">{{ timerCount }}</div>
					{{ $t('labels.resend_code') }}
				</v-btn>
				<v-btn
					depressed
					class="mft-button"
					color="primary"
					@click="login"
					min-width="100px"
					:tabindex="2"
					:disabled="!code || code.length !== 6"
					:loading="loading"
				>
					{{ $t('labels.verify') }}
				</v-btn>
			</v-card-actions>
		</div>
		<div v-else>
			<v-container> {{ $t('errors.session_expired') }} </v-container>
		</div>
	</v-card>
</template>

<script>
import PincodeInput from 'vue-pincode-input';

export default {
	name: 'MultiFactorAuth',
	components: {
		PincodeInput
	},
	data: () => ({
		expired: false,
		internation: {},
		body: null,
		code: '',
		loading: false,
		mailLoading: false,
		error: '',
		timerCount: 0
	}),
	async created() {
		if (!Object.keys(this.$route.params).length)
			this.$router.replace({ name: 'Signin', query: this.$route.query });
		this.body = this.$route.params;
		if (!this.body.mfaAppEnabled) await this.sendMail();
	},
	computed: {
		isCountingDown() {
			return this.timerCount > 0;
		},
		resendButtonLabel() {
			return this.isCountingDown
				? this.$t('labels.resend_disable', { second: this.timerCount })
				: this.$t('labels.resend_code');
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				// this.$refs.form.validate();
			}
		},
		code() {
			if (this.code.length !== 6) return;
			this.login();
		}
	},
	methods: {
		async sleep(ms) {
			return new Promise((r) => {
				setTimeout(r, ms);
			});
		},
		async login() {
			try {
				this.loading = true;
				await this.sleep(1200);

				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/login`, {
					...this.body,
					code: this.code
				});
				if (location) {
					window.location.href = location;
				}
			} catch (e) {
				switch (e.response.status) {
					case 403:
						this.error = 'verification_code';
						break;
					case 400:
						// 主にセッション切れの場合
						this.expired = true;
						break;
					default:
						// 通信エラー
						this.error = 'unknown';
						break;
				}
			} finally {
				this.loading = false;
			}
		},
		async sendMail() {
			const { email, password } = this.body;
			try {
				this.mailLoading = true;
				await this.$axios.post(`/api/auth/${this.$route.query.uid}/sendmail`, {
					email,
					password
				});
				this.timerCount = 30;
				this.error = '';
				this.timerStart();
			} catch (e) {
				this.error = 'unknown';
			} finally {
				this.mailLoading = false;
			}
		},
		timerStart() {
			const timer = setInterval(() => {
				this.timerCount -= 1;
				if (this.timerCount <= 0) {
					clearInterval(timer);
				}
			}, 1000);
		}
	}
};
</script>
<style lang="sass">
#multi-factor-auth
	.vue-pincode-input
		font-size: 2rem
		@media screen and (min-width: 600px)
			max-width: 50px
		@media screen and (max-width: 600px)
			width: 100%

.mft-button
	width: 120px

.countdown
	height: 22px
	width: 22px
	border-radius: 50%
	line-height: 22px
	text-align: center
	background-color: rgba(0, 0, 0, 0.1)
	color: white
	font-family: system-ui, -apple-system, "Hiragino Sans", "Yu Gothic UI", "Segoe UI", "Meiryo", sans-serif
	margin-right: 2px
</style>
