import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/pages/views/HomeView.vue';
import SigninPage from '@/pages/views/SigninPage.vue';
import SelectAccount from '@/pages/views/SelectAccount.vue';
import MultiFactorAuth from '@/pages/views/MultiFactorAuth.vue';
import AccountRecovery from '@/pages/views/AccountRecovery.vue';
// import OldSignupVerify from '@/pages/views/old/SignupVerify.vue';
// import OldSignupPage from '@/pages/views/old/SignupPage.vue';
import SignupIframePage from '@/pages/views/SignupIframePage.vue';
import SignupPage from '@/pages/views/SignupPage.vue';
import SignupCatstorePage from '@/pages/views/SignupCatstorePage.vue';
import SignupMail from '@/pages/views/SignupMail.vue';
import TosPage from '@/pages/views/TosPage.vue';

import VueGtag from 'vue-gtag';
import VueGtm from '@gtm-support/vue2-gtm';
import ConsentPage from '@/pages/views/ConsentPage.vue';

Vue.use(VueRouter);

const routes = [
	{ path: '/signup/iframe', name: 'SignupIframePage', component: SignupIframePage },
	{
		path: '/tos',
		name: 'TosPage',
		component: TosPage
	},
	{
		path: '/',
		component: HomeView,
		children: [
			{
				path: '/signin',
				name: 'Signin',
				component: SigninPage
			},
			{
				path: '/consent',
				name: 'Consent',
				component: ConsentPage
			},
			{
				path: '/select_account',
				name: 'SelectAccount',
				component: SelectAccount
			},
			{
				path: '/recovery',
				name: 'AccountRecovery',
				component: AccountRecovery
			},
			{
				path: '/signin/mfa',
				name: 'MultiFactorAuth',
				component: MultiFactorAuth
			},
			// src/pages/views/old下の旧サインアップ
			// {
			// 	path: '/signup',
			// 	name: 'Signup',
			// 	component: OldSignupPage
			// },
			// {
			// 	path: '/signup/verify/:code',
			// 	name: 'SignupVerify',
			// 	component: OldSignupVerify
			// },
			{
				path: '/signup',
				name: 'SignupMail',
				component: SignupMail
			},
			{
				path: '/signup/:code',
				name: 'SignupPage',
				component: SignupPage
			},
			{
				path: '/signup/catstore/:code',
				name: 'SignupCatstorePage',
				component: SignupCatstorePage
			}
		]
	},
	{
		path: '*',
		beforeEnter() {
			window.location = 'https://www.shiftinc.jp/';
		}
	}
];

const isProduction = () => {
	const { port, protocol, hostname } = window.location;

	if (port) return false;
	if (protocol === 'https:') {
		if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return false;
		if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1) return false;
		if (hostname.startsWith('green.')) return false;
	}
	return true;
};

const router = new VueRouter({
	mode: 'history',
	routes:
		process.env.NODE_ENV === 'production'
			? routes.filter((route) => !route.developmentOnly)
			: routes
});

Vue.use(
	VueGtag,
	{
		config: {
			id: isProduction() ? process.env.VUE_APP_GTAG_PRODUCTION : process.env.VUE_APP_GTAG_SANDBOX
		}
	},
	router
);

Vue.use(VueGtm, { id: isProduction() ? 'GTM-KXM7MFF' : 'GTM-DUMYDUMY' });

export default router;
