<template>
	<v-app>
		<navigation-header />

		<v-main>
			<v-container class="mt-10">
				<v-row justify="space-around">
					<router-view />
				</v-row>
			</v-container>
		</v-main>

		<navigation-footer />
	</v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue';
import NavigationFooter from '@/components/NavigationFooter.vue';

export default {
	name: 'App',
	components: { NavigationHeader, NavigationFooter }
};
</script>
