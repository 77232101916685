<template>
	<v-app>
		<v-main>
			<v-col
				v-if="!errorMessageLabel"
				cols="12"
				class="d-flex align-center"
				style="height: 100%; flex: auto"
			>
				<v-card :width="cardWidth" class="mx-auto pa-0" id="signup" elevation="0">
					<v-stepper v-model="step" alt-labels>
						<v-stepper-items>
							<v-stepper-content step="1" class="pa-5">
								<div v-if="logoUri">
									<v-img :src="logoUri" class="my-3" contain height="40" />
								</div>
								<div v-else>
									<v-img
										:src="require('@/assets/shift_logo.svg')"
										class="my-3"
										contain
										height="40"
									/>
								</div>

								<v-card-title> {{ $t('titles.signup') }}</v-card-title>
								<v-card-subtitle class="caption grey--text text--darken-1">
									{{ $t('messages.registration_with_client', { client: clientName }) }}
								</v-card-subtitle>
								<v-card-text class="pb-0">
									<v-alert
										border="left"
										type="error"
										dense
										dismissible
										close-label="alert-list"
										class="body-2"
										icon="mdi-alert-octagon-outline"
										:value="!!alertAreaMessageLabel"
										@input="alertAreaMessageLabel = null"
									>
										<span v-if="alertAreaMessageLabel">
											{{ $t(`errors.${alertAreaMessageLabel}`) }}
										</span>
									</v-alert>

									<v-form v-model="valid.step1" ref="step1" @submit.prevent>
										<EmailInput
											v-model="account.email"
											exists-check
											:label="$t('labels.signup_email')"
											:tabindex="1"
											:readonly="!!invitedEmail"
											:validate-on-blur="!invitedEmail"
											:hint="$t('hints.signup_email')"
											persistent-hint
											prepend-icon=""
											outlined
										/>
										<PrivacyPolicyAndServiceAgreement
											v-model="agreedAt"
											@service-agreement-id="account.tosId = $event"
											@on-error="onPrivacyPolicyAndServiceAgreementError"
										/>
									</v-form>
								</v-card-text>
								<v-card-actions class="justify-center px-4">
									<vue-recaptcha
										ref="recaptcha"
										:sitekey="recaptchaSitekey"
										loadRecaptchaScript
										@verify="sendmail"
										badge="bottomright"
										size="invisible"
										language="$i18n.locale"
									/>
									<v-btn
										color="primary"
										width="100%"
										@click="submit"
										:disabled="!valid.step1"
										large
									>
										{{ $t('labels.send_mail') }}
									</v-btn>
								</v-card-actions>
								<v-card-text>
									<div class="mt-1 caption grey--text text--lighten-1" align="center">
										<p class="ma-0 pa-0">{{ $t('messages.recaptcha') }}</p>
									</div>
								</v-card-text>
							</v-stepper-content>

							<v-stepper-content step="2" class="pa-0">
								<v-container>
									<v-row justify="center" align-content="center">
										<v-card width="600" class="main" elevation="0">
											<v-row justify="center" align-content="center" style="margin: 10px 0">
												<v-icon x-large>mdi-check-circle-outline</v-icon>
											</v-row>
											<v-row justify="center" align-content="center" style="margin: 10px 0">
												<v-card-title>
													{{ $t('messages.send_verification_code_v2.title') }}
												</v-card-title>
												<v-card-subtitle>{{
													$t('messages.send_verification_code_v2.subtitle')
												}}</v-card-subtitle>
											</v-row>
										</v-card>
									</v-row>
								</v-container>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card>
			</v-col>
			<v-col v-else class="justify-center" cols="12">
				<v-alert
					border="left"
					type="error"
					dense
					class="justify-center text-subtitle-2 mx-auto"
					:width="alertWidth"
					icon="mdi-alert-octagon-outline"
					:value="!!errorMessageLabel"
					@input="errorMessageLabel = null"
				>
					<span v-if="errorMessageLabel"> {{ $t(`errors.${errorMessageLabel}`) }} </span>
				</v-alert>
			</v-col>
		</v-main>
	</v-app>
</template>

<script>
import camelcaseKeys from 'camelcase-keys';

import VueRecaptcha from 'vue-recaptcha';
import EmailInput from '@/components/Input/EmailInput.vue';
import PrivacyPolicyAndServiceAgreement from '@/components/PrivacyPolicyAndServiceAgreement.vue';

export default {
	name: 'SignupMail',
	components: {
		VueRecaptcha,
		EmailInput,
		PrivacyPolicyAndServiceAgreement
	},
	data: () => ({
		errorMessageLabel: null,
		alertAreaMessageLabel: null,
		valid: { step1: false },
		step: 1,
		loading: false,
		agreedAt: null,
		account: {
			type: null,
			email: '',
			locale: null,
			invitationCode: null,
			tosId: null,
			tosAgreedAt: null,
			client: null,
			signupType: 'catstore'
		},
		invitedEmail: null,
		logoUri: null,
		clientName: null
	}),
	computed: {
		recaptchaSitekey() {
			switch (this.envIdentify()) {
				case 'local':
					return process.env.VUE_APP_RECAPTCHA_SITEKEY_LOCAL;
				case 'sandbox':
					return process.env.VUE_APP_RECAPTCHA_SITEKEY_SANDBOX;
				case 'production':
					return process.env.VUE_APP_RECAPTCHA_SITEKEY_PRODUCTION;
				default:
					return null;
			}
		},
		cardWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
					return '100%';
				default:
					return 450;
			}
		},
		alertWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
					return '';
				default:
					return 450;
			}
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				this.$refs[`step${this.step}`].resetValidation();
			}
		},
		agreedAt: {
			async handler() {
				this.account.tosAgreedAt = this.agreedAt;
			}
		}
	},
	async created() {
		const query = camelcaseKeys(this.$route.query);

		if (query.code && query.code.match(/^\w{8,32}$/)) {
			this.account.invitationCode = query.code;
			try {
				const {
					data: { invitedEmail }
				} = await this.$axios.get(`/api/v2/private/signup/invite/${query.code}`);
				this.invitedEmail = invitedEmail;
				this.account.email = invitedEmail;
			} catch (e) {
				// 無効な招待コード
				this.errorMessageLabel = 'invitation_code';
			}
		} else if (query.accountType && ['personal', 'business'].includes(query.accountType))
			this.account.type = query.accountType;

		if (query.uiLocales) {
			const locales = query.uiLocales.split(' ');
			const availableLocales = new Set(this.$i18n.availableLocales);
			for (let i = 0; i < locales.length; i += 1) {
				const locale = locales[i];
				if (availableLocales.has(locale)) {
					this.$i18n.locale = locale;
					break;
				}
			}
		}
		const { clientId, redirectUri, state } = query;
		if (!clientId) return;
		try {
			const params = {};
			if (redirectUri) {
				// ここではstateのバリデーションを行っていないためバリデーションエラーが発生するのがsubmit後
				if (!query.state) throw new Error(`state parameter required`);
				params.redirectUri = redirectUri;
			}

			const {
				data: { clientName, logoUri }
			} = await this.$axios.get(`/api/v2/private/client/${clientId}`, { params });

			this.account.client = {
				clientId,
				callback: null
			};
			if (redirectUri) {
				this.account.client.callback = {
					redirectUri,
					state
				};
			}

			this.clientName = clientName.startsWith('labels.') ? this.$t(clientName) : clientName;
			this.logoUri = logoUri;
		} catch (e) {
			// 無効なクライアント（処理は継続）
			console.error(e.toString());
		}
	},
	methods: {
		onPrivacyPolicyAndServiceAgreementError() {
			this.alertAreaMessageLabel = 'unknown';
		},
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		},
		async submit() {
			this.loading = true;
			this.$refs.recaptcha.execute();
		},
		async sendmail(recaptcha) {
			this.account.locale = this.$i18n.locale;
			try {
				await this.$axios.post(`/api/v2/private/signup/sendmail`, { ...this.account, recaptcha });
				this.setStep(2);

				if (this.envIdentify() === 'production')
					this.$gtm.trackEvent({
						event: 'email_verify',
						category: 'email_verify',
						label: 'send_email',
						value: this.account.email.includes(`@shiftinc.jp`) ? 1 : 0
					});

				this.$gtag.event('email_verify', {
					event_category: 'email_verify',
					event_label: 'send_email',
					value: this.account.email.includes(`@shiftinc.jp`) ? 1 : 0
				});
			} catch (e) {
				this.$refs.recaptcha.reset();
				this.alertAreaMessageLabel = 'unknown';
				switch (e.response?.status) {
					case 409:
						this.alertAreaMessageLabel = 'email_duplicated';
						break;
					default:
				}
			} finally {
				this.loading = false;
			}
		},
		envIdentify() {
			const { port, protocol, hostname } = window.location;

			if (port) return 'local';
			if (protocol === 'https:') {
				if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return 'dev';
				if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1)
					return 'sandbox';
				if (hostname.startsWith('green.')) return 'green';
			}
			return 'production';
		}
	}
};
</script>
<style lang="sass" scoped>
#signup::v-deep
	.grecaptcha-badge
		bottom: 0px !important

	@media screen and (min-width: 768px)
		.main
			margin-top: 20px
			padding: 20px 40px

	.v-stepper
		box-shadow: none !important
		.v-stepper__header
			box-shadow: none
	.sendmail
		white-space: pre
input:-webkit-autofill
	-webkit-box-shadow: 0 0 0px 1000px white inset !important
.caption::v-deep
	a
		text-decoration: none
</style>
