<template>
	<v-app>
		<v-main>
			<v-row>
				<v-col v-if="!errorMessageLabel" cols="12">
					<v-card elevation="0">
						<v-card-text>
							<v-container class="overflow-y-auto">
								<v-row>
									<div class="text-h5 text--primary my-4">
										{{ $t('labels.service_agreement') }} {{ tosTitle }}
									</div>
									<div v-html="tosMarkedText" />
									<div>{{ tosRevisionOn | dateFormat }}</div>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-else class="justify-center mt-5" cols="12">
					<v-alert
						border="left"
						type="error"
						dense
						class="justify-center text-subtitle-2 mx-auto"
						:width="alertWidth"
						icon="mdi-alert-octagon-outline"
						:value="!!errorMessageLabel"
						@input="errorMessageLabel = null"
					>
						<span v-if="errorMessageLabel"> {{ $t(`errors.${errorMessageLabel}`) }} </span>
					</v-alert>
				</v-col>
			</v-row>
		</v-main>
	</v-app>
</template>

<script>
import { marked } from 'marked';
import { DateTime, Settings } from 'luxon';
import camelcaseKeys from 'camelcase-keys';

export default {
	name: 'TosPage',
	components: {},
	data: () => ({
		errorMessageLabel: null,
		tosTitle: '',
		tosRevisionOn: '',
		tosBody: '',
		locale: 'ja'
	}),
	computed: {
		alertWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
					return '';
				default:
					return 450;
			}
		},
		tosMarkedText() {
			return marked(this.tosBody);
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				await this.fetchTos();
			}
		}
	},
	filters: {
		dateFormat(value) {
			if (!value) return '';
			return DateTime.fromFormat(value, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_FULL);
		}
	},
	async created() {
		const { locale } = camelcaseKeys(this.$route.query);

		const availableLocales = new Set(this.$i18n.availableLocales);
		if (locale && availableLocales.has(locale)) this.locale = locale;

		Settings.defaultLocale = this.locale;
		this.$i18n.locale = this.locale;

		await this.fetchTos();
	},
	methods: {
		async fetchTos() {
			try {
				const {
					data: { title, revisionOn, body }
				} = await this.$axios.get(`/api/v2/private/tos/jp/${this.locale}`);
				this.tosTitle = title;
				this.tosRevisionOn = revisionOn;
				this.tosBody = body;
			} catch (e) {
				this.errorMessageLabel = 'fail_fetch_tos';
			}
		}
	}
};
</script>
