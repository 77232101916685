<template>
	<v-select
		:value="value"
		@input="onInput"
		:items="items"
		:label="label"
		:clearable="clearable"
		:prepend-icon="prependIcon"
		:required="required"
		:rules="rules"
		:tabindex="tabindex"
		:item-text="
			(item) => {
				return $t(`positions_select.${item.i18nLabel}`);
			}
		"
		item-value="id"
	>
		<template #label v-if="required">
			{{ label }}
			<span style="color: red"> *</span>
		</template>
	</v-select>
</template>
<script>
export default {
	model: {
		prop: `value`
	},
	props: {
		value: {
			type: String,
			default: null
		},
		label: {
			type: String,
			default: 'Position'
		},
		required: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		prependIcon: {
			type: String,
			default: 'mdi-badge-account-outline'
		},
		tabindex: {
			type: Number,
			default: 0
		}
	},
	i18n: {
		messages: {
			en: {
				positions_select: {
					above_board_menber: 'Board Members and above',
					head_of_department: 'Head of Department',
					section_head: 'Section Head',
					director: 'Director',
					others: 'Others'
				}
			},
			ja: {
				positions_select: {
					above_board_menber: '役員以上',
					head_of_department: '部長クラス',
					section_head: '課長クラス',
					director: '主任クラス',
					others: 'その他'
				}
			}
		}
	},
	data: () => ({
		items: [
			{ i18nLabel: 'above_board_menber' },
			{ i18nLabel: 'head_of_department' },
			{ i18nLabel: 'section_head' },
			{ i18nLabel: 'director' },
			{ i18nLabel: 'others' }
		]
	}),
	computed: {
		rules() {
			return [(v) => !!v || this.$t('errors.required', { key: this.label })];
		}
	},
	methods: {
		onInput(value) {
			this.$emit(`input`, value);
		}
	}
};
</script>
