<template>
	<div>
		<v-row v-if="givenNamePreferred">
			<v-col cols="12" :md="colsMd" :sm="colsSm">
				<v-text-field
					v-model="givenName"
					:rules="givenNameRules"
					:counter="32"
					:label="$t('labels.given_name')"
					:required="required"
					:clearable="clearable"
					:prepend-icon="singleLine ? 'mdi-account' : ''"
					@change="chagend"
					persistent-hint
					:hint="hintGivenName"
					validate-on-blur
					autocomplete="off"
					:tabindex="tabindex"
				>
					<template #label v-if="required">
						{{ $t('labels.given_name') }}
						<span style="color: red"> *</span>
					</template>
				</v-text-field>
			</v-col>
			<v-col
				cols="12"
				:md="colsMd"
				:sm="colsSm"
				v-if="middleNameEanbled"
				class="pl-11 pl-md-3 pl-lg-3 pl-xg-3"
			>
				<v-text-field
					v-model="middleName"
					:counter="32"
					:label="$t('labels.middle_name')"
					:clearable="clearable"
					:hint="$t('labels.optional')"
					persistent-hint
					@change="chagend"
					validate-on-blur
					autocomplete="off"
					:tabindex="tabindex"
				>
					<template #label v-if="required">
						{{ $t('labels.middle_name') }}
						<span style="color: red"> *</span>
					</template>
				</v-text-field>
			</v-col>
			<v-col
				cols="12"
				:md="colsMd"
				:sm="colsSm"
				:class="{
					'pl-11': this.singleLine,
					'pl-md-3': this.singleLine,
					'pl-lg-3': this.singleLine,
					'pl-xg-3': this.singleLine
				}"
			>
				<v-text-field
					v-model="familyName"
					:rules="familyNameRules"
					:counter="32"
					:label="$t('labels.family_name')"
					:required="required"
					:clearable="clearable"
					@change="chagend"
					persistent-hint
					:hint="hintFamilyName"
					validate-on-blur
					autocomplete="off"
					:tabindex="tabindex"
				>
					<template #label v-if="required">
						{{ $t('labels.family_name') }}
						<span style="color: red"> *</span>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12" :md="colsMd" :sm="colsSm">
				<v-text-field
					:prepend-icon="singleLine ? 'mdi-account' : ''"
					v-model="familyName"
					:rules="familyNameRules"
					:counter="32"
					:label="$t('labels.family_name')"
					:required="required"
					:clearable="clearable"
					@change="chagend"
					persistent-hint
					:hint="hintFamilyName"
					validate-on-blur
					autocomplete="off"
					:tabindex="tabindex"
				>
					<template #label v-if="required">
						{{ $t('labels.family_name') }}
						<span style="color: red"> *</span>
					</template>
				</v-text-field>
			</v-col>
			<v-col
				cols="12"
				:md="colsMd"
				:sm="colsSm"
				v-if="middleNameEanbled"
				class="pl-11 pl-md-3 pl-lg-3 pl-xg-3"
			>
				<v-text-field
					v-model="middleName"
					:counter="32"
					:label="$t('labels.middle_name')"
					:clearable="clearable"
					:hint="$t('labels.optional')"
					persistent-hint
					@change="chagend"
					validate-on-blur
					autocomplete="off"
					:tabindex="tabindex"
				>
					<template #label v-if="required">
						{{ $t('labels.middle_name') }}
						<span style="color: red"> *</span>
					</template>
				</v-text-field>
			</v-col>
			<v-col
				cols="12"
				:md="colsMd"
				:sm="colsSm"
				:class="{
					'pl-11': this.singleLine,
					'pl-md-3': this.singleLine,
					'pl-lg-3': this.singleLine,
					'pl-xg-3': this.singleLine
				}"
			>
				<v-text-field
					v-model="givenName"
					:rules="givenNameRules"
					:counter="32"
					:label="$t('labels.given_name')"
					:required="required"
					:clearable="clearable"
					persistent-hint
					:hint="hintGivenName"
					@change="chagend"
					validate-on-blur
					autocomplete="off"
					:tabindex="tabindex"
				>
					<template #label v-if="required">
						{{ $t('labels.given_name') }}
						<span style="color: red"> *</span>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
		<v-row v-if="!disableSelectFullName">
			<v-scroll-y-transition>
				<v-col cols="12" md="8" sm="12" v-show="givenName && familyName">
					<v-select
						v-model="fullName"
						prepend-icon="mdi-account-check"
						:items="fullNamePattern"
						:required="required"
						:clearable="clearable"
						:label="$t('labels.full_name')"
						:rules="[(v) => !!v || $t('errors.required', { key: $t('labels.full_name') })]"
						validate-on-blur
						@change="chagend"
						:tabindex="tabindex"
					>
						<template #label v-if="required">
							{{ $t('labels.full_name') }}
							<span style="color: red"> *</span>
						</template>
					</v-select>
				</v-col>
			</v-scroll-y-transition>
		</v-row>
	</div>
</template>
<script>
export default {
	name: 'NameInput',
	model: {
		prop: `inputName`
	},
	props: {
		inputName: {
			type: Object,
			default: () => ({
				givenName: null,
				familyName: null,
				middleName: null,
				name: null
			})
		},
		hintFamilyName: {
			type: String,
			default: '',
			require: false
		},
		hintGivenName: {
			type: String,
			default: '',
			require: false
		},
		tabindex: {
			type: Number,
			default: 0
		},
		required: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		disableSelectFullName: {
			type: Boolean,
			default: false
		},
		singleLine: {
			type: Boolean,
			default: false
		},
		inputMiddleName: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		givenName: null,
		familyName: null,
		middleName: null,
		fullName: null
	}),
	created() {
		this.init();
	},
	computed: {
		// TODO 対応言語が増えた場合はここを修正
		middleNameEanbled() {
			if (!this.inputMiddleName) return false;
			if (this.$i18n.locale === 'en') return true;
			return false;
		},
		givenNamePreferred() {
			if (this.$i18n.locale === 'en') return true;
			return false;
		},
		givenNameRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('labels.given_name') }),
				(v) =>
					(v && v.length <= 32) ||
					this.$t('errors.maxlen', { key: this.$t('labels.given_name'), maxlen: 32 })
			];
		},
		familyNameRules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('labels.family_name') }),
				(v) =>
					(v && v.length <= 32) ||
					this.$t('errors.maxlen', { key: this.$t('labels.family_name'), maxlen: 32 })
			];
		},
		colsMd() {
			return this.singleLine ? 4 : 12;
		},
		colsSm() {
			return this.singleLine ? 4 : 12;
		},
		fullNamePattern() {
			const pattern = [];
			if (!this.familyName || !this.givenName) return [];
			pattern.push(
				`${this.familyName} ${this.givenName}`,
				`${this.familyName}, ${this.givenName}`,
				`${this.givenName} ${this.familyName}`,
				`${this.givenName}, ${this.familyName}`
			);
			if (this.middleNameEanbled && this.middleName) {
				pattern.push(
					`${this.givenName}, ${this.familyName} ${this.middleName.slice(0, 1).toUpperCase()}`,
					`${this.givenName} ${this.middleName.slice(0, 1).toUpperCase()} ${this.familyName} `,
					`${this.givenName} ${this.middleName} ${this.familyName}`,
					`${this.familyName}, ${this.givenName} ${this.middleName}`
				);
			}
			return pattern;
		},
		emitFullName() {
			if (this.disableSelectFullName) {
				if (this.middleNameEanbled)
					// 英語の場合、givenName familyName（名姓）の順番になる
					return this.middleName
						? `${this.givenName} ${this.middleName} ${this.familyName}`
						: `${this.givenName} ${this.familyName}`;

				// 日本語の場合、familyName givenName（姓名）の順番になる
				return `${this.familyName} ${this.givenName}`;
			}
			return this.fullName;
		}
	},
	watch: {
		inputName: {
			immediate: true,
			handler() {
				this.init();
			}
		}
	},
	methods: {
		init() {
			this.givenName = this.inputName.givenName;
			this.familyName = this.inputName.familyName;
			this.middleName = this.inputName.middleName;
			this.fullName = this.inputName.name;
		},
		chagend() {
			if (this.givenName) this.givenName = this.givenName.trim();
			if (this.familyName) this.familyName = this.familyName.trim();
			if (this.middleName) this.middleName = this.middleName.trim();

			if (!this.middleNameEanbled) this.middleName = null;

			this.$emit(`input`, {
				givenName: this.givenName,
				familyName: this.familyName,
				middleName: this.middleNameEanbled && this.middleName ? this.middleName : null,
				name: this.emitFullName
			});
		}
	}
};
</script>
