<template>
	<v-card max-width="560" class="mx-5 my-10 pa-0" elevation="1">
		<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
		<div v-if="!expired">
			<v-card-title> {{ $t('titles.authorize') }}</v-card-title>
			<v-form ref="form">
				<v-container>
					<v-row>
						<v-col cols="12" md="5">
							<v-card flat v-if="interaction.client">
								<div v-if="interaction.client.logoUri">
									<v-img
										:src="interaction.client.logoUri"
										contain
										height="75"
										width="75"
										max-width="250"
									/>
								</div>
								<div v-else>
									<v-img :src="require('@/assets/app.png')" contain height="75" max-width="250" />
								</div>
								<v-card-title>{{ interaction.client.clientName }}</v-card-title>
								<v-card-text>
									<div>{{ interaction.client.developerName }}</div>
									<div v-if="interaction.client.clientDescription">
										{{ interaction.client.clientDescription }}
									</div>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" md="7" class="py-5 left-border-dot">
							<v-list subheader dense>
								<v-subheader>{{ $t(`messages.authorize`) }}</v-subheader>
								<v-list-item v-for="scope in additionalResourceScopes" :key="scope">
									<v-list-item-icon>
										<v-icon>mdi-plus-circle</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ $t(`scopes.${scope}`) }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-for="scope in existsResourceScopes" :key="scope">
									<v-list-item-icon>
										<v-icon>mdi-check-circle-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ $t(`scopes.${scope}`) }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-for="scope in additionalOIDCScopes" :key="scope">
									<v-list-item-icon>
										<v-icon>mdi-plus-circle</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ $t(`scopes.${scope}`) }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-for="scope in existsOIDCScopes" :key="scope">
									<v-list-item-icon>
										<v-icon>mdi-check-circle-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ $t(`scopes.${scope}`) }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-col>
					</v-row>
					<v-card-actions class="mt-5">
						<v-spacer />
						<v-btn depressed color="primary" @click="accept" :loading="loading">
							{{ $t('labels.authorize') }}
						</v-btn>
						<v-btn depressed class="ml-5" @click="abort">
							{{ $t('labels.abort') }}
						</v-btn>
					</v-card-actions>
				</v-container>
			</v-form>
		</div>
		<div v-else>
			<v-container> {{ $t('errors.session_expired') }} </v-container>
		</div>
	</v-card>
</template>

<script>
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';

export default {
	name: 'ConsentPage',
	components: {},
	data: () => ({
		expired: false,
		interaction: {},
		sendData: null,
		existsResourceScopes: [],
		existsOIDCScopes: [],
		additionalResourceScopes: [],
		additionalOIDCScopes: [],
		loading: false
	}),
	async created() {
		this.sendData = {
			uid: this.$route.query.uid
		};
		if (this.$route.query.sig) this.sendData.sig = this.$route.query.sig;

		if (!Object.keys(this.$route.params).length) {
			try {
				const { data } = await this.$axios.post(
					`/api/auth/${this.$route.query.uid}`,
					this.sendData
				);
				this.interaction = data;
				if (this.interaction.type !== 'consent') {
					this.$router.replace({ name: 'Signin', query: this.$route.query });
					return;
				}
			} catch (e) {
				this.expired = true;
				return;
			}
		} else {
			this.interaction = this.$route.params;
		}

		// eslint-disable-next-line no-unused-vars
		const { client, details, params } = this.interaction;
		if (client.firstParty) await this.accept();

		const requestScopes = params.scopes;

		const allowResourceScopes = client.resourceScopes || [];
		const allowOIDCScopes = client.oidcScopes || [];

		if (details.missingResourceScopes) {
			Object.keys(details.missingResourceScopes).forEach((indicator) => {
				const scopes = intersection(allowResourceScopes, details.missingResourceScopes[indicator]);
				scopes.forEach((scope) => {
					this.additionalResourceScopes.push(scope);
				});
			});
		}

		if (details.missingOIDCScope) {
			const scopes = intersection(allowOIDCScopes, details.missingOIDCScope);
			scopes
				.filter((scope) => scope !== 'openid')
				.forEach((scope) => {
					this.additionalOIDCScopes.push(scope);
				});
		}

		if (requestScopes) {
			this.existsResourceScopes = difference(
				intersection(allowResourceScopes, requestScopes),
				this.additionalResourceScopes
			);

			this.existsOIDCScopes = difference(
				intersection(allowOIDCScopes, requestScopes),
				this.additionalOIDCScopes
			);
		}

		// if (details.missingOIDCClaims) this.claims = details.missingOIDCClaims;
		if (!this.additionalResourceScopes.length && !this.additionalOIDCScopes.length) {
			await this.accept();
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				// this.$refs.form.validate();
			}
		}
	},
	methods: {
		async accept() {
			this.loading = true;
			try {
				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/confirm`, this.sendData);
				if (location) {
					window.location.href = location;
				}
			} catch (e) {
				this.expired = true;
			} finally {
				this.loading = false;
			}
		},
		async abort() {
			try {
				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/abort`, this.sendData);
				if (location) {
					window.location.href = location;
				}
			} catch (e) {
				this.expired = true;
			}
		}
	}
};
</script>
<style lang="sass" scoped>
.left-border-dot
	@media screen and (min-width: 768px)
		border-left: dotted 1px
</style>
