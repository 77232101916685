<template>
	<v-card max-width="560" class="mx-5 mt-10 pa-0" elevation="1">
		<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
		<div v-if="!expired">
			<v-card-title> {{ $t('titles.choose_an_account') }}</v-card-title>
			<v-list three-line>
				<v-list-item-group color="primary">
					<template v-for="item in items">
						<v-list-item :key="item.title" @click="selectEmail(item.email)">
							<v-list-item-avatar>
								<v-img v-if="item.picture" :src="item.picture" />
								<v-img v-else :src="require('@/assets/account.svg')" min-height="100px" contain />
							</v-list-item-avatar>
							<v-list-item-content>
								<!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
								<v-list-item-title v-html="item.email" />
								<!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
								<v-list-item-subtitle v-html="item.name" />
							</v-list-item-content>
							<v-list-item-action>
								<v-btn icon>
									<v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</template>
					<v-divider />
				</v-list-item-group>
			</v-list>
			<v-card-actions>
				<v-spacer />
				<v-btn class="ma-2" outlined color="indigo" @click="selectEmail()">
					<v-icon left> mdi-account-switch </v-icon>
					{{ $t('labels.switch_account') }}
				</v-btn>
			</v-card-actions>
		</div>
		<div v-else>
			<v-container> {{ $t('errors.session_expired') }} </v-container>
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'SelectAccount',
	components: {},
	data: () => ({
		items: [],
		expired: false
	}),
	async created() {
		if (!Object.keys(this.$route.params).length) {
			this.expired = true;
		} else {
			this.interaction = this.$route.params;
		}

		this.interaction = this.$route.params;
		const { email, account } = this.interaction;

		this.items.push({
			email,
			name: account.name,
			picture: account.picture || null
		});
	},

	watch: {
		'$i18n.locale': {
			async handler() {
				this.$refs.signin.resetValidation();
			}
		}
	},
	methods: {
		async selectEmail(email = null) {
			const data = {
				uid: this.$route.query.uid,
				switch: !email
			};
			if (this.$route.query.sig) data.sig = this.$route.query.sig;

			try {
				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/continue`, data);
				if (location) {
					window.location.href = location;
				}
			} catch (e) {
				this.expired = true;
			}
		}
	}
};
</script>
<style lang="sass"></style>
