<template>
	<v-app-bar app color="white" dark :class="[environment]">
		<div class="d-flex" id="logo">
			<v-img :src="require('@/assets/logo_head.svg')" class="my-3" contain height="40" />
		</div>

		<v-spacer />

		<!-- eslint-disable-next-line vue/no-template-target-blank -->
		<v-btn href="https://www.shiftinc.jp/" target="_blank" text>
			<v-icon>mdi-dark mdi-open-in-new</v-icon>
		</v-btn>

		<v-menu bottom left transition="slide-y-transition">
			<template v-slot:activator="{ on, attrs }">
				<v-btn dark icon v-bind="attrs" v-on="on">
					<v-icon>mdi-dark mdi-earth</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-subheader>{{ $t('labels.language') }}</v-subheader>
				<v-list-item-group>
					<v-list-item v-for="(language, i) in languages" :key="i">
						<v-list-item-content @click="changeLocale(language.locale)">
							<v-list-item-title>{{ language.label }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-menu>
	</v-app-bar>
</template>

<script>
export default {
	name: 'NavigationHeader',
	data: () => ({
		environment: 'env-production',
		languages: [
			{
				locale: 'ja',
				label: '日本語'
			},
			{
				locale: 'en',
				label: 'English'
			}
		]
	}),
	created() {
		this.environment = this.envIdentify();
	},
	methods: {
		changeLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
		},
		envIdentify() {
			const { port, protocol, hostname } = window.location;

			if (port) return 'env-local';
			if (protocol === 'https:') {
				if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return 'env-dev';
				if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1)
					return 'env-sandbox';
				if (hostname.startsWith('green.')) return 'env-green';
			}
			return 'env-production';
		}
	}
};
</script>

<style lang="sass" scoped>
#logo::v-deep
	.v-responsive__content
		width: 150px !important

.env-template
	&::after
		text-align: center
		position: absolute
		border-radius: 0 0 5px 5px
		height: 16px
		left: calc(50% - 50px)
		color: white
		font-size: 6px
		width: 100px

header.env-local
		border-bottom: 3px solid rgba(128, 128, 128, 0.8) !important
		&::after
			@extend .env-template
			content: 'Local Development'
			background-color:  rgba(128, 128, 128, 0.8)
header.env-dev
		border-bottom: 3px solid rgba(69, 90, 100, 0.8) !important
		&::after
			@extend .env-template
			content: 'AWS Development'
			background-color:  rgba(69, 90, 100, 0.8)
header.env-sandbox
		border-bottom: 3px solid rgba(255, 160, 0, 0.8) !important
		&::after
			@extend .env-template
			content: 'Sandbox'
			background-color:  rgba(255, 160, 0, 0.8)
header.env-green
		border-bottom: 3px solid rgb(46, 125, 50, 0.8) !important
		&::after
			@extend .env-template
			content: 'Production/Green'
			background-color:  rgba(46, 125, 50, 0.8)
</style>
