<template>
	<v-menu
		ref="menu"
		v-model="menu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		min-width="290px"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="selectedViewDate"
				:label="$t('labels.birthday')"
				prepend-icon="mdi-calendar"
				readonly
				v-bind="attrs"
				v-on="on"
				:clearable="clearable"
				:rules="rules"
			>
				<template #label v-if="required">
					{{ $t('labels.birthday') }}
					<span style="color: red"> *</span>
				</template>
			</v-text-field>
		</template>
		<v-date-picker
			ref="picker"
			v-model="pickerDate"
			:active-picker.sync="activePicker"
			:min="maxDate"
			:max="minDate"
			@change="save"
			:locale="$i18n.locale"
			:day-format="(date) => new Date(date).getDate()"
		/>
	</v-menu>
</template>
<script>
import { DateTime } from 'luxon';

export default {
	model: {
		prop: `date`
	},
	props: {
		date: {
			type: String,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		}
	},
	data: () => ({
		activePicker: null,
		menu: false,
		pickerDate: null,
		selectedDate: null,
		selectedViewDate: null
	}),
	created() {
		this.init();
	},
	watch: {
		birthday: {
			immediate: true,
			handler() {
				this.init();
			}
		},
		menu(val) {
			if (!val) return;
			setTimeout(() => {
				this.activePicker = 'YEAR';
			}, 0);
		},
		'$i18n.locale': {
			handler() {
				if (this.selectedDate)
					this.selectedViewDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd')
						.setLocale(this.$i18n.locale)
						.toLocaleString(DateTime.DATE_FULL);
			}
		}
	},
	computed: {
		minDate() {
			return DateTime.now().plus({ years: -10 }).toFormat('yyyy-12-31');
		},
		maxDate() {
			return DateTime.now().plus({ years: -100 }).toFormat('yyyy-01-01');
		},
		rules() {
			return [
				(v) => !!v || this.menu || this.$t('errors.required', { key: this.$t('labels.birthday') })
			];
		}
	},
	methods: {
		init() {
			if (this.date) {
				this.pickerDate = DateTime.fromFormat(this.date, 'yyyy-MM-dd').toFormat('yyyy-MM-dd');
				this.selectedDate = this.pickerDate;
				this.selectedViewDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd')
					.setLocale(this.$i18n.locale)
					.toLocaleString(DateTime.DATE_FULL);
			} else {
				this.pickerDate = DateTime.now().plus({ years: -30 }).toFormat('yyyy-01-01');
				this.selectedDate = null;
				this.selectedViewDate = null;
			}
		},
		save(date) {
			this.$refs.menu.save(date);
			this.selectedDate = date;
			this.selectedViewDate = DateTime.fromFormat(this.selectedDate, 'yyyy-MM-dd')
				.setLocale(this.$i18n.locale)
				.toLocaleString(DateTime.DATE_FULL);
			this.$emit(`input`, this.selectedDate);
		}
	}
};
</script>
