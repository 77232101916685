<template>
	<div>
		<vue-tel-input-vuetify
			:single-line="true"
			mode="national"
			:rules="rules"
			:label="label"
			@input="onInput"
			:required="required"
			:clearable="clearable"
			:placeholder="null"
			validate-on-blur
			:maxLen="maxLen"
			autocomplete="off"
			disabledFetchingCountry
			:defaultCountry="defaultCountry"
			:selectLabel="$t('labels.country')"
			@country-changed="countryChanged"
			:onlyCountries="onlyCountries"
		>
			<template #label v-if="required">
				{{ label }}
				<span style="color: red"> *</span>
			</template>
		</vue-tel-input-vuetify>
	</div>
</template>

<script>
export default {
	model: {
		prop: `value`
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		maxLen: {
			type: Number,
			default: 32
		},
		onlyCountries: {
			type: Array,
			default: () => []
		},
		defaultCountry: {
			type: String,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: 'Phone number'
		}
	},
	data() {
		return {
			phone: {
				number: '',
				valid: false,
				country: undefined
			}
		};
	},
	i18n: {
		messages: {
			en: {
				labels: {
					country: 'Country',
					telephone_number: 'Cell phone'
				}
			},
			ja: {
				labels: {
					country: '所在国',
					telephone_number: '電話番号'
				}
			}
		}
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				this.init();
			}
		}
	},
	computed: {
		rules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.$t('labels.telephone_number') }),
				(v) =>
					(v && this.phone.valid) ||
					this.$t('errors.valid', { key: this.$t('labels.telephone_number') })
			];
		},
		hint() {
			return this.phone.number || '';
		}
	},
	methods: {
		init() {
			this.phone.number = this.value;
		},
		onInput(formattedNumber, { number, valid, country }) {
			this.phone.number = number.international;
			this.phone.valid = valid;
			this.phone.country = country && country.name;
			this.$emit(`input`, this.phone.number);
		},
		countryChanged(data) {
			this.$emit(`country-changed`, data);
		}
	}
};
</script>
