<template>
	<div class="mt-10">
		<v-col v-if="!errorMessageLabel" cols="12">
			<v-card :width="cardWidth" class="mx-auto pa-0" id="signup">
				<v-stepper v-model="step" alt-labels>
					<v-stepper-header>
						<v-stepper-step :complete="step > 1" step="1">
							{{ $t('labels.step') }}1
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 2" step="2">
							{{ $t('labels.step') }}2
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 3" step="3">
							{{ $t('labels.completed') }}
						</v-stepper-step>
					</v-stepper-header>
					<v-stepper-items>
						<v-stepper-content step="1" class="pa-0">
							<div v-if="logoUri">
								<v-img :src="logoUri" class="my-3" contain height="40" />
							</div>
							<div v-else>
								<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
							</div>
							<v-card-title> {{ $t('titles.signup') }}</v-card-title>
							<v-form v-model="valid.step1" ref="step1" @submit.prevent>
								<v-container>
									<v-row>
										<v-col cols="12" md="8">
											<v-row>
												<v-col cols="12" v-if="showAccountTypeTab">
													<v-icon>mdi-account-check</v-icon>
													<v-btn-toggle v-model="account.type" tile color="primary" group>
														<v-btn value="personal" large>{{
															$t('labels.personal_account')
														}}</v-btn>
														<v-btn value="business" large>{{
															$t('labels.business_account')
														}}</v-btn>
													</v-btn-toggle>
													<div class="caption text--secondary ml-8">
														<span v-if="account.type === 'personal'">
															{{ $t('hints.personal_account') }}
														</span>
														<span v-else>
															{{ $t('hints.business_account') }}
														</span>
													</div>
												</v-col>
												<v-col cols="12" md="12">
													<EmailInput
														v-model="account.email"
														exists-check
														:label="$t('labels.email')"
														:tabindex="0"
														:readonly="true"
														:validate-on-blur="false"
													/>
												</v-col>
												<v-col cols="12" md="12">
													<PasswordInput
														v-model="account.password"
														:label="$t('labels.password')"
														:hint="$t('hints.password', { minlen: 8 })"
														validator
														strength-meter
														:tabindex="0"
													/>
												</v-col>
											</v-row>
											<v-card-actions>
												<v-spacer />
												<v-btn
													color="primary"
													min-width="100px"
													@click="setStep(2)"
													:disabled="!valid.step1"
												>
													{{ $t('labels.next') }}
												</v-btn>
											</v-card-actions>
										</v-col>
										<v-col cols="12" md="4" class="d-none d-md-block">
											<v-row class="subtitle-2">{{ $t('messages.notes') }}</v-row>
											<v-row>
												<v-img
													:src="
														require('@/assets/flat-icon-design.com/f_f_business_54_svg_f_business_54_0nbg.svg')
													"
													class="my-3"
													contain
												/>
											</v-row>
										</v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-stepper-content>

						<v-stepper-content step="2" class="pa-0">
							<v-form v-model="valid.step2" ref="step2" @submit.prevent>
								<v-container>
									<v-alert dense type="info" border="left" color="info">
										{{ $t('messages.all_input_required') }}
									</v-alert>
									<div v-if="account.type !== 'business'">
										<!-- Personal Account -->
										<v-row>
											<v-col cols="12" md="12" sm="12">
												<NameInput
													v-model="account.personal.name"
													single-line
													input-middle-name
													:tabindex="0"
													required
												/>
												<DatePicker
													v-model="account.personal.birthdate"
													:label="$t('labels.birthday')"
													:tabindex="0"
													required
												/>
											</v-col>
										</v-row>
									</div>
									<div v-else>
										<!-- Business Account -->
										<v-alert dense border="left" elevation="0" tile text color="primary">
											{{ $t('labels.organization_details') }}
										</v-alert>
										<v-row class="mb-8">
											<v-col cols="12" md="12" sm="12">
												<VueCountryCode
													@onSelect="onSelectContry"
													:preferredCountries="['JP', 'US', 'VN', 'CN', 'KR', 'TW', 'HK']"
													defaultCountry="JP"
													:dropdownOptions="{ disabledDialCode: true }"
												/>
												<CustomTextField
													v-model="account.business.businessName"
													:label="$t('labels.business_name')"
													:hint="$t('hints.business_name')"
													prepend-icon=""
													:tabindex="0"
													required
												/>
											</v-col>
										</v-row>
										<v-alert dense border="left" elevation="0" tile text color="primary">
											{{ $t('labels.personal_details') }}
										</v-alert>
										<v-row>
											<v-col cols="12" md="12" sm="12">
												<NameInput
													v-model="account.personal.name"
													single-line
													input-middle-name
													:tabindex="0"
													required
												/>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12" md="6" sm="6">
												<DatePicker v-model="account.personal.birthdate" :tabindex="0" required />
											</v-col>
										</v-row>
									</div>
									<v-card-actions>
										<v-spacer />
										<v-btn color="secondary" min-width="100px" @click="setStep(1)">
											{{ $t('labels.prev') }}
										</v-btn>
										<v-btn
											color="primary"
											min-width="100px"
											@click="submit"
											:disabled="!valid.step2"
											:tabindex="0"
										>
											{{ $t('labels.next') }}
										</v-btn>
									</v-card-actions>
								</v-container>
							</v-form>
						</v-stepper-content>
						<v-stepper-content step="3" class="ma-0">
							<div v-if="logoUri">
								<v-img :src="logoUri" class="my-3" contain height="40" />
							</div>
							<div v-else>
								<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
							</div>
							<v-container>
								<v-row justify="center" align-content="center">
									<div class="sendmail text-body-2 text-center pa-5">
										{{ $t('messages.created_v2') }}
									</div>
								</v-row>

								<v-card-actions>
									<v-spacer />
									<v-btn color="secondary" min-width="100px" v-if="authUri" :href="authUri">
										{{ $t('labels.login') }}
									</v-btn>
								</v-card-actions>
							</v-container>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-card>
		</v-col>
		<v-col v-else align="center" cols="12">
			<v-alert
				align="start"
				border="left"
				type="error"
				dense
				class="text-subtitle-2 mx-auto"
				:width="alertWidth"
				icon="mdi-alert-octagon-outline"
				:value="!!errorMessageLabel"
				@input="errorMessageLabel = null"
			>
				<span v-if="errorMessageLabel"> {{ $t(`errors.${errorMessageLabel}`) }} </span>
			</v-alert>
		</v-col>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import VueCountryCode from 'vue-country-code';
import { getCountry } from 'vue-tel-input-vuetify/src/lib/utils';

import DatePicker from '@/components/DatePicker.vue';
import NameInput from '@/components/Input/NameInput.vue';
import EmailInput from '@/components/Input/EmailInput.vue';
import PasswordInput from '@/components/Input/PasswordInput.vue';
import CustomTextField from '@/components/Input/CustomTextField.vue';

export default {
	name: 'SignupPage',
	components: {
		DatePicker,
		NameInput,
		PasswordInput,
		EmailInput,
		CustomTextField,
		VueCountryCode
	},
	data: () => ({
		errorMessageLabel: null,
		alertAreaMessageLabel: null,
		valid: {
			step1: false,
			step2: false,
			step3: false
		},
		step: 1,
		loading: false,
		account: {
			code: null,
			type: 'personal',
			password: '',
			email: '',
			personal: {
				birthdate: null,
				name: {
					givenName: '',
					familyName: '',
					middleName: null,
					name: ''
				}
			},
			business: {
				businessName: '',
				countryCode: 'jp'
			},
			zoneinfo: null
		},
		showAccountTypeTab: false,
		clientId: null,
		clientName: null,
		logoUri: null,
		authUri: null
	}),
	computed: {
		cardWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
					return '100%';
				default:
					return 800;
			}
		},
		alertWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
					return '';
				default:
					return 800;
			}
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				this.$refs[`step${this.step}`].resetValidation();
			}
		}
	},
	async created() {
		const { code } = this.$route.params;

		if (!code) {
			this.errorMessageLabel = 'verification_code';
			return;
		}
		try {
			const {
				data: { type, client, email, code: sessionCode }
			} = await this.$axios.post(`/api/v2/private/signup/verify`, {
				code
			});

			if (type) this.account.type = type;
			else this.showAccountTypeTab = true;

			if (client) {
				this.clientId = client.clientId;
				if (client.callback) this.authUri = client.callback.authUri;
			}

			this.account.email = email;
			this.account.code = sessionCode;
		} catch (e) {
			this.errorMessageLabel = 'unknown';
			switch (e.response?.status) {
				case 400:
					this.errorMessageLabel = 'verification_code';
					break;
				case 404:
					this.errorMessageLabel = 'verification_code';
					break;
				default:
			}
		}

		try {
			const country = await getCountry();
			this.account.business.countryCode = country.toLowerCase();
		} catch (e) {
			console.log(e);
		}

		if (!this.clientId) return;

		try {
			const {
				data: { clientName, logoUri }
			} = await this.$axios.get(`/api/v2/private/client/${this.clientId}`);

			this.logoUri = logoUri;
			this.clientName = clientName;
		} catch (e) {
			// 無効なクライアント（処理は継続）
			this.errorMessageLabel = 'unknown';
		}
	},
	async mounted() {
		try {
			this.account.zoneinfo = Intl.DateTimeFormat().resolvedOptions().timeZone;
			// chromeバグで"Etc/GMT-9"が戻る場合がある（OS側で再度タイムゾーンを再設定すると直る）
			if (this.account.zoneinfo.match(/^Etc\/GMT/)) delete this.account.zoneinfo;
		} catch (e) {
			// 古いブラウザでは取得できないため該当値はnullとなる
			// https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
		}
	},
	methods: {
		async onSelectContry(v) {
			this.account.business.countryCode = v.iso2.toLowerCase();
		},
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		},
		async submit() {
			this.loading = true;

			try {
				const account = cloneDeep(this.account);
				delete account.email;
				if (account.type !== 'business') delete account.business;

				await this.$axios.post(`/api/v2/private/signup`, { ...account });
				this.setStep(3);

				if (this.envIdentify() === 'production')
					this.$gtm.trackEvent({
						event: 'signup_success',
						category: 'signup',
						label: 'is_new_user',
						value: this.account.email.includes(`@shiftinc.jp`) ? 1 : 0
					});

				this.$cookies.config(300);
				this.$cookies.set('signup-session', account.code);
			} catch (e) {
				this.alertAreaMessageLabel = 'unknown';
				switch (e.response?.status) {
					case 409:
						this.alertAreaMessageLabel = 'email_duplicated';
						break;
					default:
				}
			} finally {
				this.loading = false;
			}
		},
		envIdentify() {
			const { port, protocol, hostname } = window.location;

			if (port) return 'local';
			if (protocol === 'https:') {
				if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return 'dev';
				if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1)
					return 'sandbox';
				if (hostname.startsWith('green.')) return 'green';
			}
			return 'production';
		}
	}
};
</script>
<style lang="sass" scoped>
#signup::v-deep
	@media screen and (min-width: 768px)
		.main
			margin-top: 20px
			padding: 20px 40px
	.v-stepper
		box-shadow: none !important
		.v-stepper__header
			box-shadow: none
	.sendmail
		white-space: pre
	.vue-country-select

		border: none
		float: left
		border-radius: 0px
		border-bottom: 1px solid rgb(118, 118, 118)
		margin-top: 13px
		ul.dropdown-list
			width: 400px
		li.dropdown-item
			padding: 2px 5px
			strong
				font-weight: normal
		.dropdown
			&.open
				background-color: initial
				box-shadow: none
			&:focus-within
				box-shadow: none
			&:-webkit-autofill
				-webkit-box-shadow: 0 0 0px 1000px white inset !important

input:-webkit-autofill
	-webkit-box-shadow: 0 0 0px 1000px white inset !important
</style>
