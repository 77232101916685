<template>
	<v-text-field
		:value="value"
		@input="onInput"
		@change="emailExists"
		:rules="rules"
		:label="label"
		:required="required"
		:clearable="clearable"
		:prepend-icon="prependIcon"
		:validate-on-blur="validateOnBlur"
		:counter="maxlen"
		:tabindex="tabindex"
		type="text"
		:readonly="readonly"
		:disabled="readonly"
		:error-messages="duplicatedErrorMessage"
		:hint="hint"
		:persistent-hint="persistentHint"
		:outlined="outlined"
		autocomplete="new"
	/>
</template>
<script>
import isEmail from 'validator/lib/isEmail';

export default {
	name: 'EmailInput',
	model: {
		prop: `value`
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: 'Email'
		},
		existsCheck: {
			type: Boolean,
			default: false
		},
		tabindex: {
			type: Number,
			default: 0
		},
		required: {
			type: Boolean,
			default: true
		},
		clearable: {
			type: Boolean,
			default: true
		},
		readonly: {
			type: Boolean,
			default: false
		},
		disableValidation: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: '',
			required: false
		},
		persistentHint: {
			type: Boolean,
			required: false,
			default: false
		},
		validateOnBlur: {
			type: Boolean,
			required: false,
			default: true
		},
		prependIcon: {
			type: String,
			required: false,
			default: 'mdi-email'
		},
		outlined: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		duplicatedErrorMessage: '',
		minlen: 6,
		maxlen: 128
	}),

	computed: {
		rules() {
			return [
				(v) => !!v || this.$t('errors.required', { key: this.label }),
				(v) =>
					this.disableValidation ||
					(v && v.length >= this.minlen) ||
					this.$t('errors.minlen', { key: this.label, minlen: this.minlen }),
				(v) =>
					this.disableValidation ||
					(v && v.length <= this.maxlen) ||
					this.$t('errors.maxlen', { key: this.label, maxlen: this.maxlen }),
				(v) => (v && isEmail(v)) || this.$t('errors.valid', { key: this.label })
			];
		}
	},
	methods: {
		async emailExists(email) {
			if (!this.existsCheck) return true;
			if (email.length < 6) return false;
			try {
				await this.$axios.get(`/api/v2/private/signup/email/exists`, {
					params: { email }
				});
				this.duplicatedErrorMessage = this.$t('errors.email_duplicated');
				return false;
			} catch (e) {
				if (e.response.status === 404) {
					this.duplicatedErrorMessage = null;
				}
				return false;
			}
		},
		async onInput(email) {
			this.$emit(`input`, email);
		}
	}
};
</script>
