<template>
	<v-card width="560" class="mx-2 mt-10 pa-0" elevation="1" id="account-recovery">
		<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
		<v-stepper v-model="step" alt-labels flat>
			<v-stepper-items>
				<v-stepper-content step="1" class="pa-0">
					<v-form v-model="valid.step1" ref="step1" @submit.prevent>
						<v-card-title> {{ $t('titles.find_account') }}</v-card-title>
						<v-card-subtitle> {{ $t('subtitles.find_account') }}</v-card-subtitle>
						<v-card-text>
							<EmailInput
								v-model="email"
								:label="$t('labels.email')"
								:tabindex="1"
								:hint="$t('hints.find_account_email')"
								persistent-hint
							/>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="secondary" min-width="100px" @click="back">
								{{ $t('labels.prev') }}
							</v-btn>
							<v-btn color="primary" min-width="100px" @click="setStep(2)" :disabled="!valid.step1">
								{{ $t('labels.next') }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="2" class="pa-0">
					<v-form v-model="valid.step2" ref="step2" @submit.prevent>
						<v-card-title> {{ $t('titles.input_name') }}</v-card-title>
						<v-card-subtitle> {{ $t('subtitles.input_name') }}</v-card-subtitle>
						<v-card-text>
							<NameInput
								v-model="name"
								disable-select-full-name
								:tabindex="2"
								:hint-family-name="$t('hints.find_account_family_name')"
								:hint-given-name="$t('hints.find_account_given_name')"
							/>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn color="secondary" min-width="100px" @click="setStep(1)">
								{{ $t('labels.prev') }}
							</v-btn>
							<v-btn
								color="primary"
								min-width="100px"
								@click="accountExists"
								:disabled="!name.givenName || !name.familyName"
							>
								{{ $t('labels.next') }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="3" class="pa-0">
					<v-card-title> {{ $t('titles.verify_account') }}</v-card-title>
					<v-card-subtitle> {{ $t('subtitles.verify_account') }}</v-card-subtitle>
					<v-card-text>
						<v-layout justify-center>
							<v-img
								:src="
									require('@/assets/flat-icon-design.com/f_f_business_10_svg_f_business_10_0.svg')
								"
								contain
								max-width="180"
							/>
						</v-layout>
						{{ $t('messages.will_send_verifycation', { email }) }}
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" min-width="100px" @click="sendmail">
							{{ $t('labels.send') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>
				<v-stepper-content step="4" class="pa-0">
					<v-form v-model="valid.step4" ref="step4" @submit.prevent>
						<v-card-title> {{ $t('titles.enter_verification') }}</v-card-title>
						<v-card-subtitle> {{ $t('subtitles.sent_verifycation', { email }) }}</v-card-subtitle>
						<v-alert
							border="left"
							type="error"
							dense
							v-if="pinInputFailed"
							class="text-subtitle-2 ma-3"
						>
							{{ errorMessage }}
						</v-alert>
						<v-card-text align="center">
							<PincodeInput v-model="recoveryCode" :length="6" :secure="false" placeholder="0" />
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								min-width="100px"
								@click="verifyRecoveryCode"
								:disabled="!valid.step4"
								:loading="loading"
							>
								{{ $t('labels.next') }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-stepper-content>

				<v-stepper-content step="5" class="pa-0">
					<v-form v-model="valid.step5" ref="step5" @submit.prevent>
						<v-card-title> {{ $t('titles.change_password') }}</v-card-title>
						<v-card-subtitle> {{ $t('subtitles.change_password') }}</v-card-subtitle>
						<v-card-text>
							<PasswordInput
								v-model="password"
								:hint="$t('hints.password', { minlen: 8 })"
								validator
								strength-meter
								:label="$t('labels.password')"
								:tabindex="1"
							/>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="primary"
								min-width="100px"
								@click="changePassword"
								:disabled="!valid.step5"
								:loading="loading"
							>
								{{ $t('labels.save_password') }}
							</v-btn>
						</v-card-actions>
					</v-form>
				</v-stepper-content>

				<v-stepper-content step="6" class="pa-0 pb-3">
					<v-card-title> {{ $t('titles.password_changed') }}</v-card-title>
					<v-card-subtitle> {{ $t('subtitles.password_changed', { email }) }}</v-card-subtitle>
					<v-card-actions v-if="false">
						<v-spacer />
						<v-btn color="primary" min-width="100px" @click="login">
							{{ $t('labels.login') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>

				<v-stepper-content step="9" class="pa-0 pb-3">
					<v-card-title> {{ $t('titles.no_account_found') }}</v-card-title>
					<v-card-subtitle> {{ $t('subtitles.no_account_found') }}</v-card-subtitle>
					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" min-width="100px" @click="setStep(1)">
							{{ $t('labels.try_again') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>
				<v-stepper-content step="10" class="pa-0 pb-3">
					<v-card-title> {{ $t('titles.login_pin_lock') }}</v-card-title>
					<v-card-subtitle> {{ $t('subtitles.login_pin_lock') }}</v-card-subtitle>
					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" min-width="100px" @click="back">
							{{ $t('labels.back_signin') }}
						</v-btn>
					</v-card-actions>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-card>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
import EmailInput from '@/components/Input/EmailInput.vue';
import NameInput from '@/components/Input/NameInput.vue';
import PasswordInput from '@/components/Input/PasswordInput.vue';

export default {
	name: 'AccountRecovery',
	components: {
		EmailInput,
		NameInput,
		PincodeInput,
		PasswordInput
	},
	data: () => ({
		expired: false,
		step: 1,
		valid: {
			step1: false,
			step2: false,
			step4: false,
			step5: false
		},
		name: {
			givenName: '',
			familyName: '',
			name: '',
			password: ''
		},
		tokenId: null,
		password: '',
		recoveryCode: '',
		email: '',
		loading: false,
		pinInputFailed: false,
		errorMessage: ''
	}),
	async created() {
		this.email = this.$route.params.email;

		if (this.$route.query.ui_locales) {
			const locales = this.$route.query.ui_locales.split(' ');
			const availableLocales = new Set(this.$i18n.availableLocales);
			for (let i = 0; i < locales.length; i += 1) {
				const locale = locales[i];
				if (availableLocales.has(locale)) {
					this.$i18n.locale = locale;
					break;
				}
			}
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				const key = `step${this.step}`;
				if (!(key in this.valid)) return;
				this.$refs[key].resetValidation();
			}
		}
	},
	methods: {
		async sleep(ms) {
			return new Promise((r) => {
				setTimeout(r, ms);
			});
		},
		setStep(step) {
			this.step = step;
			this.$gtag.pageview(`${this.$route.path}?step=${step}`);
		},
		async accountExists() {
			try {
				const {
					data: { tokenId }
				} = await this.$axios.post(`/api/v2/private/recovery/account`, {
					email: this.email,
					givenName: this.name.givenName,
					familyName: this.name.familyName
				});
				this.tokenId = tokenId;
				this.setStep(3);
				return true;
			} catch (e) {
				if (e.response?.status === 404) this.setStep(9);
				return false;
			}
		},
		async sendmail() {
			try {
				await this.$axios.post(
					`/api/v2/private/recovery/account/sendmail`,
					{
						sendmail: true
					},
					{
						headers: {
							Authorization: `Bearer ${this.tokenId}`
						}
					}
				);
				this.setStep(4);
				return true;
			} catch (e) {
				if (e.response?.status === 404) this.setStep(9);
				return false;
			}
		},
		async verifyRecoveryCode() {
			this.loading = true;
			await this.sleep(1200);
			try {
				const {
					data: { tokenId }
				} = await this.$axios.post(
					`/api/v2/private/recovery/account/verify`,
					{
						recoveryCode: this.recoveryCode
					},
					{
						headers: {
							Authorization: `Bearer ${this.tokenId}`
						}
					}
				);
				this.errorMessage = ``;
				this.tokenId = tokenId;
				this.setStep(5);
				return true;
			} catch (e) {
				switch (e.response?.status) {
					case 403:
						this.pinInputFailed = true;
						this.recoveryCode = '';
						this.errorMessage = this.$t('errors.verification_code');
						break;
					case 423:
						// アカウントロックの場合
						this.recoveryCode = '';
						this.pinInputFailed = true;
						this.errorMessage = this.$t('errors.login_pin_locked');
						this.setStep(10);
						break;
					default:
						break;
				}
				return false;
			} finally {
				this.loading = false;
			}
		},
		async changePassword() {
			this.loading = true;
			await this.sleep(1200);
			try {
				await this.$axios.put(
					`/api/v2/private/recovery/account/password`,
					{
						password: this.password
					},
					{
						headers: {
							Authorization: `Bearer ${this.tokenId}`
						}
					}
				);
				this.setStep(6);
				return true;
			} catch (e) {
				return false;
			} finally {
				this.loading = false;
			}
		},
		async login() {
			this.$router.push({
				name: 'Signin',
				params: {
					email: this.email
				},
				query: this.$route.query
			});
		},
		async back() {
			this.$router.push({
				name: 'Signin',
				params: {
					login_hint: this.email
				},
				query: {
					...this.$route.query,
					locale: this.$i18n.locale
				}
			});
		}
	}
};
</script>
<style lang="sass" scoped>
#account-recovery::v-deep
	.v-stepper
		box-shadow: none !important
		.v-stepper__header
			box-shadow: none
	.vue-pincode-input
		max-width: 50px
		font-size: 2rem
</style>
