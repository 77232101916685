<template>
	<v-card width="560" class="mx-2 mt-10 pa-0" elevation="1" id="signin">
		<v-img :src="require('@/assets/shift_logo.svg')" class="my-3" contain height="40" />
		<v-card-title> {{ $t('titles.signin') }}</v-card-title>
		<div v-if="!expired">
			<v-alert
				border="left"
				type="error"
				dense
				class="body-2 ma-3"
				:value="!!errorMessage"
				@input="errorMessage = null"
			>
				<span v-if="errorMessage"> {{ errorMessage }} </span>
			</v-alert>
			<v-form v-model="valid" ref="signin" @submit.prevent>
				<div class="text-center ma-5" v-if="session">
					<v-progress-circular :size="70" :width="7" color="primary" :indeterminate="loading" />
				</div>

				<v-container v-else>
					<v-row>
						<v-col cols="12" md="12">
							<EmailInput
								v-model="email"
								disable-validation
								:label="$t('labels.email')"
								:tabindex="1"
								:readonly="emailReadonly"
							/>
						</v-col>
						<v-col cols="12" md="12">
							<PasswordInput
								v-model="password"
								:label="$t('labels.password')"
								@blur="$refs.signin.validate()"
								disable-validation
								:tabindex="2"
							/>
						</v-col>
						<v-col cols="12" md="12">
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-switch
										v-model="rememberme"
										inset
										:label="rememberme ? $t('labels.remember_me') : $t('labels.log_me_out_later')"
										class="ml-5"
										dense
										v-bind="attrs"
										v-on="on"
									/>
								</template>
								<span>{{ $t('messages.automatic_login_note') }}</span>
							</v-tooltip>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="6">
							<div class="ml-5">
								<v-icon>mdi-dark mdi-lock-reset</v-icon>
								<span class="caption" @click="accountRecovery" @keypress="accountRecovery">
									{{ $t('labels.password_reset') }}
								</span>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<v-card-actions>
								<v-spacer />
								<v-btn class="ml-5" color="secondary" @click="abort">
									{{ $t('labels.abort') }}
								</v-btn>
								<v-btn
									type="submit"
									color="primary"
									min-width="100PX"
									@click="login"
									tabindex="3"
									:loading="loading"
								>
									{{ $t('labels.login') }}
								</v-btn>
							</v-card-actions>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</div>
		<div v-else>
			<v-container> {{ $t('errors.session_expired') }} </v-container>
		</div>
	</v-card>
</template>

<script>
import EmailInput from '@/components/Input/EmailInput.vue';
import PasswordInput from '@/components/Input/PasswordInput.vue';

// 二段階認証がアプリ認証である場合にサーバサイドで生成されるコード
// メールの場合は84567ec8d64b54594df83bdf5ba46b59
// TODO: path変更などでコード変更時は挙動が変わるので、検知する仕組みを作る
const MFA_APP = 'af26ff5878bf7d559d2742c446ce6c9e';

export default {
	name: 'SigninPage',
	components: {
		PasswordInput,
		EmailInput
	},
	data: () => ({
		expired: false,
		valid: true,
		interaction: null,
		password: '',
		email: '',
		rememberme: true,
		body: null,
		params: {},
		tosUrl: null,
		policyUri: null,
		emailReadonly: false,
		loading: false,
		errorMessage: '',
		session: null
	}),
	async created() {
		if (!this.$route.query.uid) {
			this.expired = true;
			return;
		}
		this.body = {
			uid: this.$route.query.uid
		};
		if (this.$route.query.sig) this.body.sig = this.$route.query.sig;

		try {
			const { data: interaction } = await this.$axios.post(
				`/api/auth/${this.$route.query.uid}`,
				this.body
			);
			this.interaction = interaction;
		} catch (e) {
			// console.log(e.message);
			// セッション切れの場合
			this.expired = true;
			return;
		}
		if (this.interaction.type === 'consent') {
			this.$router.replace({
				name: 'Consent',
				params: this.interaction,
				query: this.$route.query
			});
			return;
		}
		if (this.interaction.type === 'select_account') {
			this.$router.replace({
				name: 'SelectAccount',
				params: this.interaction,
				query: this.$route.query
			});
			return;
		}

		const { params } = this.interaction;

		if (this.interaction.email) {
			this.email = this.interaction.email;
			// this.emailReadonly = true;
		} else if (params.loginHint) this.email = params.loginHint;

		if (this.$route.params.email) {
			this.email = this.$route.params.email;
			this.emailReadonly = true;
		}

		if (this.$route.params.loginHint) {
			this.email = this.$route.params.loginHint;
		}

		if (!this.$route.query.locale && params.uiLocales) {
			const locales = params.uiLocales.split(' ');
			const availableLocales = new Set(this.$i18n.availableLocales);

			for (let i = 0; i < locales.length; i += 1) {
				const locale = locales[i];
				if (availableLocales.has(locale)) {
					this.$i18n.locale = locale;
					break;
				}
			}
		}

		const session = this.$cookies.get('signup-session');
		if (session) {
			this.session = session;
			await this.sessionLogin();
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				this.$refs.signin.resetValidation();
			}
		}
	},
	methods: {
		async sleep(ms) {
			return new Promise((r) => {
				setTimeout(r, ms);
			});
		},
		async accountRecovery() {
			this.$router.push({
				name: 'AccountRecovery',
				params: {
					email: this.email
				},
				query: this.$route.query
			});
		},

		async sessionLogin() {
			if (!this.session) return;
			try {
				this.loading = true;
				await this.sleep(1200);

				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/login`, {
					...this.body,
					session: this.session,
					remember: !!this.rememberme,
					switchAccount: !this.emailReadonly
				});

				this.$gtag.event('login_success', {
					event_category: 'signin',
					event_label: 'is_internal_member',
					value: this.email.includes(`@shiftinc.jp`) ? 1 : 0
				});

				if (location) window.location.href = location;
			} catch (e) {
				this.$refs.signin.resetValidation();
				this.session = null;
			} finally {
				this.loading = false;
			}
		},
		async login() {
			await this.$refs.signin.validate();
			if (!this.valid) return;

			try {
				this.loading = true;
				await this.sleep(1200);

				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/login`, {
					...this.body,
					remember: !!this.rememberme,
					switchAccount: !this.emailReadonly,
					email: this.email,
					password: this.password
				});

				this.$gtag.event('login_success', {
					event_category: 'signin',
					event_label: 'is_internal_member',
					value: this.email.includes(`@shiftinc.jp`) ? 1 : 0
				});

				if (location) window.location.href = location;
			} catch (e) {
				this.$gtag.event('login_fail', {
					event_category: 'signin',
					event_label: 'status_code',
					value: e.response?.status || 0
				});

				switch (e.response?.status) {
					case 400:
						// 主にセッション切れの場合
						this.expired = true;
						break;
					case 401:
						// 追加の二段階認証が要求された
						this.$router.push({
							name: 'MultiFactorAuth',
							params: {
								...this.body,
								email: this.email,
								password: this.password,
								remember: !!this.rememberme,
								clientId: this.interaction.params.clientId,
								mfaAppEnabled: e.response.data.code === MFA_APP
							},
							query: this.$route.query
						});
						break;
					case 403:
						this.password = '';
						this.$refs.signin.resetValidation();
						this.errorMessage = this.$t('errors.login');
						break;
					case 404:
						// サインアップセッションを用いてログインしようとしたがセッションが存在しない
						this.$refs.signin.resetValidation();
						break;
					case 423:
						// アカウントロックの場合
						this.password = '';
						this.$refs.signin.resetValidation();
						this.errorMessage = this.$t('errors.locked');
						break;
					default:
						// 通信エラー
						break;
				}
			} finally {
				this.loading = false;
			}
		},
		async abort() {
			try {
				const {
					data: { location }
				} = await this.$axios.post(`/api/auth/${this.$route.query.uid}/abort`, this.body);

				this.$gtag.event('login_abort', {
					event_category: 'signin',
					event_label: 'is_internal_member',
					value: this.email.includes(`@shiftinc.jp`) ? 1 : 0
				});

				if (location) window.location.href = location;
			} catch (e) {
				// セッション切れ
				this.expired = true;
			}
		}
	}
};
</script>
<style lang="sass">
#signin
	.caption:hover
		text-decoration: underline
input:-webkit-autofill
	-webkit-box-shadow: 0 0 0px 1000px white inset !important
</style>
